

















import { AnyObject, Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { AsyncComponent, VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'
import { Inject } from '../../../../support'

import { AuthMixin, IAuthMixin, UserModel } from '../../../auth/shared'

import { CartModel, CheckoutPayload } from '../../contracts'

import { Step } from '../Step'
import {
  CIRCULAR_STEPPER_COMPONENT_KEY,
  defaultComponentConfig,
  stepsComponentConstructor
} from './CircularStepper.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CircularStepper>({
  name: 'CircularStepper',
  components: { Step },
  created (): void {
    this.config = this.getComponentConfig(CIRCULAR_STEPPER_COMPONENT_KEY, defaultComponentConfig)
    this.$emit('step', { step: this._currentStep, lastStep: this.steps.length + 1 })
  }
})
export class CircularStepper extends Mixins<IAuthMixin, StructureConfigurable>(AuthMixin, StructureConfigurable) {
  @Prop({ type: Object, required: true })
  public cart!: CartModel

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @PropSync('payload', { type: Object, required: true })
  public _payload!: CheckoutPayload

  @PropSync('currentStep', { type: Number, required: true })
  public _currentStep!: number

  public data: AnyObject = {}
  public error: string | null = null

  /**
   * Determines indices that should not have visible Step component
   */
  public get hiddenStepsIndices (): Array<string | number> {
    return this.getConfigProperty('hiddenStepsIndices')
  }

  public get stepsComponentConstructor (): VueConstructor | AsyncComponent {
    const possibleSteps = Object.values(this.steps).map((step) => {
      return step.type
    })

    return stepsComponentConstructor[possibleSteps[this._currentStep - 1]][this.steps[this._currentStep - 1].version]
  }

  public get steps (): AnyObject[] {
    return Object.values(this.getConfigProperty<AnyObject>('steps')).filter((step: AnyObject) => {
      return this._payload.isUserLoggedIn ? true : !step.auth
    })
  }

  public onError (message: string): void {
    this.error = message
  }

  public isSignupRequested (value: boolean): void {
    this._payload = {
      ...this._payload,
      isSignupRequested: value
    }
  }

  /**
   * Handles next step.
   */
  public nextStep (): void {
    this.error = null
    this._currentStep++
  }

  public onSaving (value: boolean): void {
    if (value) this.error = null
    this.$emit('saving', value)
  }

  /**
   * Updates the payload by a new value
   */
  public updateKey (key: string, value: unknown): void {
    this._payload = {
      ...this._payload,
      [key]: value
    }
  }

  public get showStep (): boolean {
    return this.isLoggedInUser ? true : !this.hiddenStepsIndices.includes(String(this._currentStep))
  }
}
export default CircularStepper
