import { AnyObject } from '@movecloser/front-core'
import { ShippingMethodCode } from '../../../../contexts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_OVERVIEW_KEY = 'CheckoutOverview'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CheckoutOverviewConfig {
  consents: AnyObject | null
  shippingIcons: Record<string, string> | null
  showRodo: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_OVERVIEW_CONFIG_MAP: CheckoutOverviewConfig = {
  consents: null,
  shippingIcons: {
    [ShippingMethodCode.Flatrate]: require('../Shippings/assets/images/dhl.svg'),
    [ShippingMethodCode.DHLKurier]: require('../Shippings/assets/images/dhl.svg'),
    [ShippingMethodCode.DPDKurier]: require('../Shippings/assets/images/dpd.svg'),
    [ShippingMethodCode.Free]: require('../Shippings/assets/images/dhl.svg'),
    // [ShippingMethodCode.InpostKurier]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.OrlenPaczkaPobranie]: require('../Shippings/assets/images/orlen.svg'),
    [ShippingMethodCode.OrlenPaczka]: require('../Shippings/assets/images/orlen.svg'),
    [ShippingMethodCode.Paczkomat]: require('../Shippings/assets/images/inpost.svg'),
    [ShippingMethodCode.PaczkomatCod]: require('../Shippings/assets/images/inpost.svg'),
    [ShippingMethodCode.DPD]: require('../Shippings/assets/images/dpd.svg'),
    [ShippingMethodCode.DPDCod]: require('../Shippings/assets/images/dpd.svg'),
    [ShippingMethodCode.AramexCreditsCardsDelivery]: require('../Shippings/assets/images/aramex.png'),
    [ShippingMethodCode.AramexOverNightDocument]: require('../Shippings/assets/images/aramex.png'),
    [ShippingMethodCode.AramexSameDayDocument]: require('../Shippings/assets/images/aramex.png'),
    [ShippingMethodCode.AramexOverNightParcel]: require('../Shippings/assets/images/aramex.png')
  // [ShippingMethodCode.UPS]: ''
  },
  showRodo: true
}
